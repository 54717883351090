import { PRINTLABEL_PERMISSION, DELETE_PERMISSION, CONTAINER_FILLING_CONTAINERPRODUCT } from "@kraftheinz/common";
import { n as normalizeComponent } from "./index.js";
import "lodash";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", { attrs: { "title": "Container Production", "scroll": { x: 1220 }, "actions-width": 120, "search-by": "Code,SampleCode,ProdLineCode,ContainerTypeCode", "ph-search-by": "Search by Container ID, Sample No, Production Line, Container Type" }, scopedSlots: _vm._u([{ key: "action", fn: function(ref) {
    var edit = ref.edit;
    var record = ref.record;
    var toggleActive = ref.toggleActive;
    return [_vm.can(_vm.permissions.delete) ? _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v(_vm._s(record.isInactive ? "Activate" : "Deactivate"))];
    }, proxy: true }], null, true) }, [_c("a-button", { staticClass: "list__action-toggle-active mx-1", attrs: { "icon": record.isInactive ? "eye-invisible" : "eye", "size": "small", "type": "link" }, on: { "click": function($event) {
      return toggleActive(record);
    } } })], 1) : _vm._e(), _vm.can(_vm.permissions.printlabel) ? _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Print")];
    }, proxy: true }], null, true) }, [_c("a-button", { staticClass: "list__action-toggle-active mx-1", attrs: { "icon": "printer", "size": "small", "type": "link" }, on: { "click": function($event) {
      return _vm.onClickPrint(record);
    } } })], 1) : _vm._e(), _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Detail")];
    }, proxy: true }], null, true) }, [_c("a-button", { staticClass: "mx-1", attrs: { "icon": "right-circle", "size": "small", "type": "link" }, on: { "click": function($event) {
      return edit(record);
    } } })], 1)];
  } }]) }, [_c("url-field", { key: "Code", attrs: { "base-url": "/container-filling/container-production", "data-index": "code", "title": "Container ID", "fixed": "left", "width": 155, "sorter": true } }), _c("text-field", { key: "SampleCode", attrs: { "data-index": "sampleCode", "title": "Sample No", "width": 145, "sorter": true } }), _c("text-field", { key: "ProdLineCode", attrs: { "data-index": "prodLineCode", "title": "Production Line", "sorter": true } }), _c("text-field", { key: "ContainerTypeCode", attrs: { "data-index": "containerTypeCode", "title": "Container Type", "width": 170, "sorter": true } }), _c("text-field", { key: "Gross", attrs: { "data-index": "gross", "title": "Gross", "width": 95, "sorter": true } }), _c("text-field", { key: "DatetimeFilled", attrs: { "data-index": "datetimeFilled", "parse": _vm.parseDatetimeFilled, "title": "Time Filled", "width": 180 } }), _c("check-box-field", { key: "Interfaced", attrs: { "data-index": "interfaced", "title": "Interfaced", "align": "center", "width": 110 } })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "ListContainerProduction",
  inject: ["can"],
  data() {
    return {
      permissions: {
        printlabel: PRINTLABEL_PERMISSION,
        delete: DELETE_PERMISSION
      }
    };
  },
  methods: {
    parseDatetimeFilled(value) {
      if (!value)
        return "";
      return this.$moment(value).format("DD/MM/YYYY hh:mm A");
    },
    onClickPrint(record) {
      this.$router.push(`/container-filling/container-production/printer/${record.id}`);
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ListContainerProduction = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "list-container-production" }, [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("resource", { attrs: { "name": "cf.containers", "api-url": _vm.apiUrl, "create-route": "/container-filling/container-production/create", "edit-route": "/container-filling/container-production/:id", "page": _vm.page } }, [_c("list-container-production")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    ListContainerProduction
  },
  data() {
    return {
      page: CONTAINER_FILLING_CONTAINERPRODUCT,
      ListContainerProduction,
      apiUrl,
      itemsMenu: [
        {
          key: "container-processing",
          title: "Container Processing",
          path: ""
        },
        {
          key: "container-production",
          title: "Container Production",
          path: "/container-filling/container-production"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
